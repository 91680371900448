// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-work-template-js": () => import("/opt/build/repo/src/templates/work-template.js" /* webpackChunkName: "component---src-templates-work-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-styleguide-js": () => import("/opt/build/repo/src/pages/styleguide/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-styleguide-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

